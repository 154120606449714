<template>
	<div>
		<div class="card p-2 flex-row justify-content-end">
			<button class="btn btn-primary" @click="modalCreateColor.show = true">Agregar</button>
		</div>

		<div class="card p-2">
			<div class="mx-2 m-2 position-relative">
				<b-row>
					<b-col
						cols="12"
						md="12"
						lg="4"
						class="d-flex align-items-center justify-content-start justify-content-sm-start"
					>
						<span class="text-muted">
							Mostrando {{ start_page }} a {{ to_page }} de {{ total_data }} registros
						</span>
					</b-col>
					<b-col cols="12" md="12" lg="8" class="flex-nowrap">
						<div class="flex flex-wrap cross-center w-full gap-x-3 xt-search-input justify-content-end">
							<div class="xt-search-input__paginate">
								<b-pagination
									v-model="current_page"
									:total-rows="total_data"
									:per-page="perpage"
									first-number
									last-number
									prev-class="prev-item"
									next-class="next-item"
								>
									<template #prev-text>
										<feather-icon icon="ChevronLeftIcon" size="18" />
									</template>
									<template #next-text>
										<feather-icon icon="ChevronRightIcon" size="18" />
									</template>
								</b-pagination>
							</div>
							<div class="xt-search-input__search">
								<div class="search-container-value">
									<b-form-input
										type="search"
										class="search-input"
										@keyup.enter="init"
										v-model="search"
										placeholder="Nombre"
										style="width: 200px"
									/>
									<div class="search-btn">
										<button @click="init" class="btn btn-primary w-100">Buscar</button>
									</div>
								</div>
							</div>
						</div>
					</b-col>
				</b-row>
			</div>

			<div class="table-responsive mb-0">
				<b-table-simple sticky-header class="table mb-0" :style="{ minHeight: '70vh' }">
					<b-thead>
						<b-tr>
							<b-th v-if="title.visible" v-for="(title, key) in headTable" :key="key">
								<div class="d-flex align-items-center justify-content-center cursor-pointer">
									<p class="mb-0 mr-1">{{ title.title }}</p>
									<!-- <FeatherIcon
										v-if="orderBy == title.key"
										:icon="order == 'asc' ? 'ArrowUpCircleIcon' : 'ArrowDownCircleIcon'"
										size="18"
									/> -->
								</div>
							</b-th>
						</b-tr>
					</b-thead>
					<b-tbody>
						<b-tr
							v-if="colours.length > 0"
							class="text-center"
							v-for="(colour, index) in colours"
							:key="index"
						>
							<b-td class="">
								<p class="mb-0 font-weight-bolder">{{ index + 1 }}</p>
							</b-td>

							<b-td class="" style="width: 300px">
								<div class="d-flex align-items-center justify-content-between">
									<p class="mb-0 font-weight-bolder">{{ colour.color }}</p>
									<FeatherIcon
										@click="openModalUpdateName(colour.id, colour.color)"
										icon="EditIcon"
										size="18"
										class="text-primary cursor-pointer mx-50"
									/>
								</div>
							</b-td>

							<b-td class="">
								<!-- <p class="mb-0 font-weight-bolder">{{ colour.gender }}</p> -->
								<div class="d-flex justify-content-center">
									<v-select
										v-model="colour.gender_id"
										label="label"
										:options="genders"
										:reduce="(opt) => opt.id"
										:clearable="false"
										transition=""
										placeholder="Seleccione un año"
										class="w-50"
										@input="updateColor($event, colour.id)"
									/>
								</div>
							</b-td>
							<b-td>
								<!-- <FeatherIcon icon="SaveIcon" size="18" class="text-primary cursor-pointer mx-50" /> -->

								<FeatherIcon
									@click="deleteColor(colour.id)"
									icon="TrashIcon"
									size="18"
									class="text-danger cursor-pointer mx-50"
								/>
							</b-td>
						</b-tr>
						<b-tr v-if="colours.length == 0">
							<b-td colspan="7" class="text-center">
								<p class="mb-0 font-weight-bold">Sin colores encontrados</p>
							</b-td>
						</b-tr>
					</b-tbody>
				</b-table-simple>
			</div>
		</div>

		<b-modal
			@ok="updateNameColor(modalUpdateName.color_id, modalUpdateName.color_text)"
			ok-title="Guardar"
			size="xs"
			centered
			v-model="modalUpdateName.show"
			:title="modalUpdateName.title"
		>
			<b-form-group label="Color">
				<b-form-input v-model="modalUpdateName.color_text" trim></b-form-input>
			</b-form-group>
		</b-modal>

		<b-modal
			@hide="hideModalCreate"
			@ok.prevent="createColor"
			v-model="modalCreateColor.show"
			:title="modalCreateColor.title"
		>
			<validation-observer ref="form">
				<ValidationProvider rules="required" v-slot="{ errors }" class="w-100">
					<b-form-group label="Color" :invalid-feedback="errors[0]" :state="errors[0] ? false : null">
						<b-form-input
							placeholder="Agregar nombre"
							v-model="modalCreateColor.name"
							trim
							:class="errors[0] ? 'is-invalid' : ''"
							@input="
								(e) => {
									modalCreateColor.name = modalCreateColor.name.toUpperCase();
								}
							"
						></b-form-input>
					</b-form-group>
				</ValidationProvider>
			</validation-observer>

			<b-form-group label="Genero">
				<v-select
					v-model="modalCreateColor.gender_id"
					label="label"
					:options="genders"
					:reduce="(opt) => opt.id"
					:clearable="false"
					transition=""
					placeholder="Seleccione un año"
					class="w-100"
					@input="updateColor($event, colour.id)"
				/>
			</b-form-group>
		</b-modal>
	</div>
</template>

<script>
import specimensService from "@/services/specimens.service";

export default {
	name: "ColourSpecimenSettings",
	data() {
		return {
			headTable: [
				{ key: "index", title: "#", visible: true },
				{ key: "name", title: "Nombre", visible: true },
				{ key: "gender", title: "Genero", visible: true },
				{ key: "actions", title: "Acciones", visible: true },
			],
			genders: [
				{
					id: null,
					label: "Ambos",
				},
				{
					id: 1,
					label: "Macho",
				},
				{
					id: 2,
					label: "Hembra",
				},
			],
			colours: [],
			modalUpdateName: {
				show: false,
				title: "Actualizar color",
				color_id: null,
				color_text: null,
			},
			modalCreateColor: {
				show: false,
				title: "Agregar",
				name: null,
				gender_id: null,
			},
			start_page: 1,
			to_page: 1,
			total_data: 1,
			current_page: 1,
			perpage: 20,
			search: null,
		};
	},
	async mounted() {
		await this.init();
	},
	methods: {
		async init() {
			this.isPreloading();
			const {
				colores: { data, from, last_page, to, total },
			} = await specimensService.getColors({
				gender_specimen: null,
				page: this.current_page,
				perpage: this.perpage,
				search: this.search,
			});
			this.isPreloading(false);
			this.start_page = from;
			this.to_page = to;
			this.total_data = total;
			this.colours = data.map((color) => {
				return {
					...color,
					gender_id_copy: color.gender_id,
				};
			});
		},
		async updateColor(gender_id, color_id) {
			const { isConfirmed } = await this.showConfirmSwal();
			if (!isConfirmed) return;
			this.isPreloading();
			const { message, success, status } = await specimensService.updateColourSpecimen({
				color_id,
				gender_id,
			});
			this.isPreloading(false);
			if (status) {
				this.showToast("success", "top-right", "Colores", "SuccessIcon", message);
			} else {
			}
			await this.init();
		},
		async deleteColor(color_id) {
			const { isConfirmed } = await this.showConfirmSwal();
			if (!isConfirmed) return;
			this.isPreloading();
			const { message, success, status } = await specimensService.deleteColourSpecimen({ color_id: color_id });
			this.isPreloading(false);
			if (status) {
				this.showToast("success", "top-right", "Colores", "SuccessIcon", message);
			} else {
			}
			await this.init();
		},
		openModalUpdateName(color_id, color_text) {
			this.modalUpdateName.show = true;
			this.modalUpdateName.color_id = color_id;
			this.modalUpdateName.color_text = color_text;
		},
		//
		async updateNameColor(id, text) {
			this.isPreloading();
			const resp = await specimensService.updateNameColourSpecimen({ color_id: id, color_text: text });
			this.isPreloading(false);
			await this.init();
		},
		async createColor() {
			if (!(await this.$refs.form.validate())) {
				this.showToast(
					"danger",
					"top-right",
					"Campos Incompletos",
					"AlertCircleIcon",
					"Faltan campos por llenar"
				);
				return;
			}
			this.isPreloading();
			const resp = await specimensService.createColourSpecimen({
				gender_id: this.modalCreateColor.gender_id,
				name: this.modalCreateColor.name,
			});
			this.isPreloading(false);

			await this.init();
			this.modalCreateColor.show = false;
			this.modalCreateColor.name = null;
			this.modalCreateColor.gender_id = null;
		},
		hideModalCreate() {
			// this.modalCreateColor.show = false;
			// this.modalCreateColor.name = null;
			// this.modalCreateColor.gender_id = null;
		},
	},
	watch: {
		async current_page() {
			await this.init();
		},
	},
};
</script>

<style lang="scss" scoped>
.xt-search-input__search {
	width: 100%;
	// margin: 1rem 0;
	display: flex;
	flex-direction: column;
	@media screen and (min-width: 1400px) {
		flex-direction: row;
		justify-content: flex-end;
	}
	.search-container-type {
		display: flex;
		h4 {
			display: flex;
			align-items: center;
			justify-content: center;
		}
	}
	.search-container-value {
		display: flex;
		flex-direction: column;
		margin-top: 0.5rem;
		@media screen and (min-width: 700px) {
			flex-direction: row;
			// margin-top: .5rem;
			margin-left: 1rem;
		}
		.search-input {
			width: 100% !important;
			@media screen and (min-width: 700px) {
				width: 50% !important;
			}
			@media screen and (min-width: 1000px) {
				width: 50% !important;
			}
		}
		.search-btn {
			margin-top: 0.5rem;
			width: 100% !important;
			@media screen and (min-width: 700px) {
				margin-left: 0.5rem;
				margin-top: 0;
				width: 50% !important;
			}
		}
	}
}
</style>
